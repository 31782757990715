<template>
  <div>
    <router-link to="/">Kalendarz</router-link> |
    <router-link to="/eventlist">Instalacje</router-link> |
    <router-link to="/contractors">Kontrahenci</router-link> |
    <router-link to="/offers-archive">Oferty</router-link> |
    <router-link to="/payments">Płatności</router-link> |
    <router-link to="/magazine">Magazyn</router-link>
    <!-- <router-link to="/customer">Klient indywidualny</router-link> | -->
    <!-- <router-link to="/company">Firma</router-link> | -->
    <!-- <router-link to="/cars">Samochody</router-link> | -->
    <!-- <router-link to="/addcontractor">Dodaj Klienta</router-link> | -->
    <!-- <router-link to="/addinstallation">Dodaj instalacje</router-link> | -->
    <!-- <router-link to="/squad">Brygady</router-link> -->
    <!-- <router-link to="/addsquad"></router-link> -->
  </div>
</template>
<script>
// import {userProfile} from '../store/index'
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
  computed: {
    ...mapState(["userProfile"]),
  },
};
</script>

<style>
.menu-button {
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
  text-align: left;
  font-size: 15px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  transition: all 0.6s;
}
.menu-button:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.75);
  background: rgba(0, 0, 0, 0.05);

  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.active > div > div {
  color: #1967d2;
  background: #e8f0fe;
  font-weight: 500;
  padding-left: 20px !important;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
</style>
