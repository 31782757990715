<template>
  <div id="app">
    <div id="nav">
      <header-menu v-if="$route.name != 'Login'"></header-menu>
      <main-menu v-if="$route.name != 'Login'"></main-menu>
    </div>
    <router-view />
    <p class="blink mt-5" @click="openVersionModal" style="font-size: 10px">wersja 2.0</p>
    <version-modal></version-modal>
  </div>
</template>
<script src="https://smtpjs.com/v3/smtp.js"></script>
<script>
import HeaderMenu from "./components/HeaderMenu";
import MainMenu from "./components/MainMenu";
import VersionModal from "./components/VersionModal";
export default {
  data() {
    return {};
  },
  components: {
    "main-menu": MainMenu,
    "header-menu": HeaderMenu,
    "version-modal": VersionModal,
  },
  methods: {
    openVersionModal() {
      this.$modal.show("version-modal");
    },
  },
};
</script>
<style>
.blink {
  animation: blinker 2s linear infinite;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
