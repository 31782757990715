import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {auth} from './firebase'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import VModal from 'vue-js-modal'
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css"




Vue.config.productionTip = false
Vue.use(VModal)
Vue.component("v-select", vSelect);

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

let app
auth.onAuthStateChanged(user => {
  if(!app) {
    app = new Vue({
      router,
      store,
      render: h=> h(App)
    }).$mount('#app')
  }
  if (user) {
    store.dispatch('fetchUserProfile', user)
  }
})