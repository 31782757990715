import firebase from "firebase/app"
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyCNeJTt-6P_Tk6wSeN5cP96k3eujIQSr2U",
    authDomain: "klimbudcrm-ee8e3.firebaseapp.com",
    databaseURL: "https://klimbudcrm-ee8e3.firebaseio.com",
    projectId: "klimbudcrm-ee8e3",
    storageBucket: "klimbudcrm-ee8e3.appspot.com",
    messagingSenderId: "625406728281",
    appId: "1:625406728281:web:7fb520b182818a9b31b948",
    measurementId: "G-5KL9H9M29T"
  };
  const fb = firebase.initializeApp(firebaseConfig)

  const db = firebase.firestore()
  const auth = firebase.auth()
  
  const contractorsCollection = db.collection('contractors')
  const usersCollection = db.collection('users')
  const installationsCollection = db.collection('installations')
  const paymentsCollection = db.collection('payments')
  const carsCollection = db.collection('cars')
  const squadsCollection = db.collection('squads')
  const employeesCollection = db.collection('employees')
  const magazineCollection = db.collection('magazine')
  const offersCollection = db.collection('offers')
  const othersCollection = db.collection('others')

  export {
    fb,
    db,
    auth,
    contractorsCollection,
    usersCollection,
    installationsCollection,
    paymentsCollection,
    carsCollection,
    squadsCollection,
    employeesCollection,
    magazineCollection,
    offersCollection,
    othersCollection

  }